
require('normalize.css/normalize.css');
require('./styles/index.scss');

import jQuery from "jquery";
import 'jquery.easing';

import 'bootstrap';
 

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);



    //new LuminousGallery(document.querySelectorAll(".gallery-demo", { showCloseButton: true}));




    /*!
    * Start Bootstrap - Agency v6.0.3 (https://startbootstrap.com/theme/agency)
    * Copyright 2013-2020 Start Bootstrap
    * Licensed under MIT (https://github.com/StartBootstrap/startbootstrap-agency/blob/master/LICENSE)
    */
    (function ($) {
        "use strict"; // Start of use strict
    
        // Smooth scrolling using jQuery easing
        $('a.js-scroll-trigger[href*="#"]:not([href="#"])').click(function () {
            if (
                location.pathname.replace(/^\//, "") ==
                    this.pathname.replace(/^\//, "") &&
                location.hostname == this.hostname
            ) {
                var target = $(this.hash);
                target = target.length
                    ? target
                    : $("[name=" + this.hash.slice(1) + "]");
                if (target.length) {
                    $("html, body").animate(
                        {
                            scrollTop: target.offset().top - 72,
                        },
                        1000,
                        "easeInOutExpo"
                    );
                    return false;
                }
            }
        });
    
        // Closes responsive menu when a scroll trigger link is clicked
        $(".js-scroll-trigger").click(function () {
            $(".navbar-collapse").collapse("hide");
        });
    
        // Activate scrollspy to add active class to navbar items on scroll
        $("body").scrollspy({
            target: "#mainNav",
            offset: 74,
        });
    
        // Collapse Navbar
        var navbarCollapse = function () {
            if ($("#mainNav").offset().top > 100) {
                $("#mainNav").addClass("navbar-shrink");
            } else {
                $("#mainNav").removeClass("navbar-shrink");
            }
        };
        // Collapse now if page is not at top
        navbarCollapse();
        // Collapse the navbar when page is scrolled
        $(window).scroll(navbarCollapse);






        // my code 
        gsap.defaults({ ease: "sine.inOut", duration:1 });
        

        // design hover
        const designColors = new gsap.timeline({ repeat: -1, defaults: {delay: 0 }} );
        designColors
            .to(".blue", { x: -5, y:-10, duration: 2.5}, 'one')
            .to(".blue", { x: -0, y: -0, duration: 2.5}, 'two')
            
            .to(".red", { x: 7, y:-6, duration: 1.7}, 'one')
            .to(".red", { x: 0, y: 0, duration: 1.7 }, 'two')
            
            .to(".yellow", { x: 12, y:-14, duration: 2.1}, 'one')
            .to(".yellow", { x: -0, y: -0, duration: 2.1 }, 'two')
            
            .to(".pink", { x: 5, y:12, duration: 1.4}, 'one')
            .to(".pink", { x: -0, y: -0, duration: 1.4}, 'two')

            // .fromTo(".persona_1", { left: 0, top:0}, { left: -40, top: 40, zIndex:1}, 2)
            // .fromTo(".persona_2", { left: -40, top: 40}, { left: 40, top: -40, zIndex:3}, 2)
            // .fromTo(".persona_3", { left: 40, top: -40}, { left: 0, top:0, zIndex:1}, 2)

            // .fromTo(".persona_1", { left: -40, top:40}, { left: 40, top: -40, zIndex:3}, 3)
            // .fromTo(".persona_2", { left: 40, top: -40}, { left: -0, top: 0, zIndex:2}, 3)
            // .fromTo(".persona_3", { left: -0, top: 0}, { left: -40, top:40, zIndex:1}, 3)
        ;

        // persona items
        const personas = new gsap.timeline({ repeat: -1, defaults: {duration: 1, delay: 2}} );
        personas
            .fromTo(".persona_1", { left: 40, top:-40,  zIndex:1}, { left: 0, top:0, zIndex:2}, 0)
            .fromTo(".persona_2", { left: 0, top: 0,    zIndex:2}, { left: -40, top: 40, zIndex:3}, 0)
            .fromTo(".persona_3", { left: -40, top: 40, zIndex:3}, { left: 40, top: -40, zIndex:1}, 0)

            .fromTo(".persona_1", { left: 0, top:0,     zIndex:2}, { left: -40, top: 40, zIndex:3}, 2)
            .fromTo(".persona_2", { left: -40, top: 40, zIndex:3}, { left: 40, top: -40, zIndex:1}, 2)
            .fromTo(".persona_3", { left: 40, top: -40, zIndex:1}, { left: 0, top:0, zIndex:2}, 2)

            .fromTo(".persona_1", { left: -40, top:40,  zIndex:3}, { left: 40, top: -40, zIndex:1}, 3)
            .fromTo(".persona_2", { left: 40, top: -40, zIndex:1}, { left: -0, top: 0, zIndex:2}, 3)
            .fromTo(".persona_3", { left: -0, top: 0,   zIndex:2}, { left: -40, top:40, zIndex:3}, 3)
        ;

        // persona graph
        function getRandomInt(max) {
            return Math.floor(Math.random() * max)+1;
        }
        
        var intervalID = window.setInterval(myCallback, 1200);

        function myCallback() {
            const dot = getRandomInt(8);
            document.querySelectorAll('#graph_dots g').forEach(item => {
                item.classList.remove('set');
            });
            document.querySelector('#dot' + dot).classList.add('set');
        }


        // intreview items
        const interview_animations = new gsap.timeline({
            scrollTrigger: {
                trigger: ".interview_animations",
                toggleActions: "play reset play reset",
                scrub: true,
                start: "top bottom",
                end: "bottom top"
            }
        });
        interview_animations
            //.from(".footer_border_top:after", { width: '0%', ease: "sine.inOut" })
            .from(".circle", { rotation:360, transformOrigin:"50% 50%" })
        ;


        // code animation
        const code_animations = new gsap.timeline({
            scrollTrigger: {
                trigger: ".grandiant_bkg",
                toggleActions: "play reset play reset",
                scrub: true,
                start: "top bottom-=200px",
                end: "+=300px"
            }
        });
        code_animations
            //.from(".footer_border_top:after", { width: '0%', ease: "sine.inOut" })
            .from(".grandiant_bkg", { opacity:0 })
        ;



        // global text animation

        document.querySelectorAll('.content_text').forEach( item => {
            const mTitle = item.querySelector('.m_title');
            const mText = item.querySelector('.m_text');
        
            const footer_bottom = new gsap.timeline({
                scrollTrigger: {
                    trigger: item,
                    toggleActions: "play reset play reset",
                    // markers: true
                }
            });
            footer_bottom
                //.from(".footer_border_top:after", { width: '0%', ease: "sine.inOut" })
                .from(mTitle, { y: -30, opacity: 0, duration:0.5 })
                .from(mText, { y: -30, opacity: 0, duration:0.5 })
            ;
            
        });


    })(jQuery); // End of use strict
    